import { LinksTabs } from "@/components/LinksTabs";
import { HomePageTwo, GetStaticProps } from "../bet-nextv1/HomePageTwo";

import { NextPage } from "next";
import axios from "axios";
// import { call } from "@/core-nextv3/util/call.api";

const HomeKkr: NextPage = (props:any) => 
{
    
    
    const props2 = Object.assign({}, props, 
        {   
            leftMenu : LinksTabs
        })

    return <HomePageTwo {...props2} />
} 



export default HomeKkr;

export const getStaticProps = GetStaticProps;